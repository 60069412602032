<template>
    <transition name="fade">
        <div v-if="open" class="modal-background"></div>
    </transition>
    <transition name="slide-fade">
        <div
            v-if="open"
            class="modal"
            :class="{ open, [modalClass]: modalClass }"
            @click="backgroundOnClick"
        >
            <div
                class="modal-frame"
                @click.stop="() => {}"
                v-bind="$attrs"
                :style="{
                    maxWidth: maxWidth || 'auto'
                }"
            >
                <span v-if="floatingClose" class="floatingClose">
                    <w3ppClose class="dark" @click="$emit('close')"/>
                </span>
                <div class="modal-header" :class="{ floatingClose }"><slot name="modal-header"></slot></div>
                <div class="modal-body"><slot name="default"></slot></div>
                <div class="modal-footer"><slot name="modal-footer"></slot></div>
            </div>
        </div>
    </transition>
</template>
<script>
import w3ppClose from '@/components/w3ppClose'
export default {
    components: {
        w3ppClose
    },
    props: {
        maxWidth: String,
        modalClass: String,
        open: Boolean,
        floatingClose: Boolean
    },
    emits: ['backgroundClick', 'close'],
    methods: {
        backgroundOnClick () {
            this.$emit('backgroundClick')
        }
    }
}
</script>
<style lang="scss" scoped>
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

span.floatingClose {
    position: absolute;
    right: 4px;
    top: 4px;
}

.modal {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 720px) {
    }

    .modal-frame {
        position: relative;
        width: 100%;
        max-height: 100%;
        display: flex;
        max-width: 720px;
        flex-direction: column;
        background: white;
        box-shadow: rgba(0, 0, 0, .3) 0 2px 3px;
        border-radius: 6px;

        .modal-header {
            flex-shrink: 0;

            &.floatingClose {
                margin-right: 36px;
            }
        }
        .modal-body {
            overflow: auto;
        }
        .modal-footer {
            flex-shrink: 0;
        }
    }
}
</style>

<style lang="scss" scoped>
    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition: all 0.2s ease-out;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateY(100%);
        opacity: 0;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
