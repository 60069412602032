import { reactive, computed } from 'vue'

const alerts = reactive([])

export const $alert = {
    message: computed(() => alerts[0]),
    show (data) {
        alerts.push(data)
    },
    close () {
        if (alerts.length) alerts.shift()
    }
}

export default {
    install: app => {
        app.config.globalProperties.$alert = $alert
    }
}
