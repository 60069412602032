// import firebase from 'firebase/app'
import { firebase } from '@firebase/app'
import { firebaseConfig } from './config'
import 'firebase/auth'
// import 'firebase/functions'
import 'firebase/firestore'
// import 'firebase/storage'
import 'firebase/analytics'
import snackBars from '@/services/snackBars'

export default firebase
firebase.initializeApp(firebaseConfig)

const localhost = ['192.168.0.106', 'localhost'].find(host => window.location.hostname === host)

// if (localhost) {
//     console.log('localhost detected!')
//     firebase.firestore().settings({
//         host: `${localhost}:8080`,
//         ssl: false
//     })
//     firebase.auth().useEmulator(`http://${localhost}:9099/`)
// }

const db = firebase.firestore()
db.enablePersistence()
    .catch((err) => {
        console.log('err', err)
        let message
        if (err.code == 'failed-precondition') {
            message = 'Multiple tabs open, persistence can only be enabled in one tab at a a time.'
        } else if (err.code == 'unimplemented') {
            message = 'The current browser does not support all of the features required to enable persistence'
        }
        if (message) snackBars.show('persistence-warning', {
            message,
            action: {
                label: 'Got it!',
                fn: () => { snackBars.delete('persistence-warning') }
            }
        })
    })

export { db }
// import "firebase/remote-config"
// export const remoteConfig = firebase.remoteConfig()
// remoteConfig.settings = {
//     minimumFetchIntervalMillis: 3600000,
// }

export const auth = firebase.auth()
// export const auth = firebase.auth(app)
// console.log('auth', app.auth)

// export const functions = app.functions()

// export const storage = firebase.storage()
// export const storage = app.storage()


export const analytics = firebase.analytics()
