<template>
    <w3pp-navbar w3ppAccountButton/>
    <router-view />
    <w3ppSnackbars />
</template>

<script>
import w3ppNavbar from '@/components/w3ppNavbar'
import w3ppSnackbars from '@/components/w3ppSnackbars'
let refreshing
export default {
    components: {
        w3ppNavbar,
        w3ppSnackbars
    },
    created () {
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (refreshing) return
                refreshing = true
                // Here the actual reload of the page occurs
                window.location.reload()
            })
        }
    },
    mounted () {
        setTimeout(() => {
            const elements = document.getElementsByClassName('firebase-emulator-warning')
            elements.forEach(el => {
                this.$snackBars.show('emulator-warning', {
                    message: el.innerText,
                    action: {
                        label: 'Got it!',
                        fn: () => { this.$snackBars.delete('emulator-warning') }
                    }
                })
            })
        }, 1000)
    }
}
</script>

<style lang="scss">
html, body, * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: inherit;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  color: #2c3e50;
  min-height: 100vh;
  background: #eff2f5;
}

.firebase-emulator-warning {
    display: none;
}
</style>
