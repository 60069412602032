<template>
    <w3pp-button
        @click="createModal = true"
        class="green"
    >Create new ledger</w3pp-button>

    <w3pp-modal
        :open="createModal"
        @backgroundClick="close"
        @close="close"
        :modalClass="'modal-wrapper'"
        max-width="448px"
        floatingClose
    >
        <template #default>
            <div class="modal-body">
                <div>Enter a title for your new ledger</div>
                <input
                    type="text" name="title" 
                    :value="title"
                    @input="title = $event.target.value.trim()"
                    @keyup.enter="create"
                >
            </div>
        </template>
        <template #modal-footer>
            <div class="modal-footer">
                <w3pp-button
                    background="#2ea44f"
                    :disabled="!title"
                    @click="create"
                    class="block"
                >Create new ledger</w3pp-button>
            </div>
        </template>
    </w3pp-modal>
</template>
<script>
import firebase, { auth, db } from '@/firebase'
import w3ppModal from "@/components/w3ppModal";
import w3ppButton from '@/components/w3ppButton'
import plusIcon from '@iconify-icons/mdi/plus'
import { Icon, addIcon } from '@iconify/vue'
addIcon('plusIcon', plusIcon)
export default {
    name: 'CreateLedger',
    components: {
        Icon,
        w3ppModal,
        w3ppButton
    },
    data () {
        return {
            createModal: false,
            title: ''
        }
    },
    methods: {
        create () {
            const title = this.title

            if (!title || !auth.currentUser.uid) return
            this.close()

            this.$snackBars.show('created', { message: `Creating ${title}` })
            const userRef = db.collection('users').doc(auth.currentUser.uid)

            db.collection('ledgers').add({
                title,
                members: firebase.firestore.FieldValue.arrayUnion(userRef)
            }).then(doc => {
                this.$router.push(`/ledger/${doc.id}`)
                this.$snackBars.show('created', { message: 'Your ledger has been created' })
                return userRef.set({
                    ledgers: firebase.firestore.FieldValue.arrayUnion(doc)
                }, { merge: true })
            })
            .catch(message => this.$snackBars.show('error', { message }))
        },
        close () {
            this.createModal = false
        }
    }
}
</script>
<style lang="scss" scoped>
.modal-body {
    font-weight: 400;
    font-size: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    input {
        height: 32px;
        border-radius: 4px;
        border: none;
        box-shadow: inset 0 0 0 1px #dadce0;
        padding: 0 8px;
        background: #f6f8f9;
    }
}
.modal-footer {
    padding: 0 16px 16px 16px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    .button {
        height: 32px;
        font-size: 13px;
        font-weight: 600;
        box-shadow: rgba(27, 31, 35, 0.1) 0px 1px 0px 0px, rgba(255, 255, 255, 0.03) 0px 1px 0px 0px inset;
    }
}


.button.green {
    font-size: 14px;
    font-weight: 500;
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    height: 32px;
    box-shadow: 0 0 0 1px rgba(27, 31, 35, 0.15);
    background-color: #2ea44f;
    color: #FFFFFF;
}
</style>