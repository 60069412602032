<template>
    <div class="close"></div>
</template>
<style lang="scss" scoped>
.close {
    position: relative;
    width: 28px;
    height: 28px;
    /* box-shadow: 0 2px 4px -1px black; */
    border-radius: 50%;
    /* background: #e82c2c; */
    cursor: pointer;
    opacity: .5;
}

.close:before,
.close:after {
    position: absolute;
    top: 5px;
    left: 13px;
    content: ' ';
    height: 18px;
    width: 2px;
    background-color: white;
}

.dark:before,
.dark:after {
    background-color: black;
}

.close:hover {
    opacity: 1;
}

.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}
</style>
