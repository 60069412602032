import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from '@/router'
import snackBars from '@/services/snackBars'
import feed from '@/services/feed'
import user, { $user } from '@/services/user'
import { $signin } from '@/services/signin'
import { authPromise } from '@/services/deferredPromises'

window.d = function (date) {
    if (date.indexOf('/') == 2) date = date.split('/').reverse().join('/')
    return date
}

Date.prototype.format = function (format){
    const day = String(this.getDate()).padStart(2, 0)
    const month = String(this.getMonth() + 1).padStart(2, 0)
    const year = this.getFullYear()
    return {
        YYYYMMDD: `${year}/${month}/${day}`,
        DDMMYYYY: `${day}/${month}/${year}`
    }[format] || `${year}/${month}/${day}`
}

$user.service.onTransition(state => {
    if (router.currentRoute.value.name) {
        if (state.matches('authenticated') && router.currentRoute.value.name === 'Signin') {
            router.replace($signin.onSignin?.redirect || '/')
            if ($signin.onSignin?.callBack) {
                $signin.onSignin.callBack()
            }
            $signin.onSignin = null
        } else if (
            state.matches('no_user') &&
            router.currentRoute.value.matched.some(record => record.meta.requiresAuth)
        ) {
            router.push('/signin')
        }
    }
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        await authPromise
        if ($user.state.value.matches('no_user') && to.name !== 'Signin') {
            $signin.onSignin = { redirect: to }
            return next('/signin')
        } else if ($user.state.value.matches('authenticated') && to.name === 'Signin') {
            return next('/')
        }
    }
    next()
})
const app = createApp(App)

app.use(router)
    .use(snackBars)
    .use(user)
    .use(feed)
    .mount('#app')
