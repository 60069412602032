import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresAuth: true },
    component: Home
  },
  {
      path: '/signin',
      name: 'Signin',
      meta: { requiresAuth: true },
      component: () =>
          import(/* webpackChunkName: "Signin" */ '../views/Signin.vue')
  },
  {
    path: '/ledger/:id',
    name: 'Ledger',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "ledger" */ '../views/Ledger.vue'),
    children: [
      {
        path: '',
        name: 'Entries',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Entries" */ '../components/ledger/Entries.vue'),
      },
      {
        path: 'overview',
        name: 'Overview',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Entries" */ '../components/ledger/Overview.vue'),
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Entries" */ '../components/ledger/Settings.vue'),
      },
      {
        path: 'list',
        name: 'ShoppingList',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Entries" */ '../components/ledger/ShoppingList.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
