import { createMachine, assign } from 'xstate'
import { auth, db } from '@/firebase'
import { $snackBars } from '@/services/snackBars'
import { $feed } from '@/services/feed'
import migration_20210712 from '@/services/migrations/2021-07-12-default-ledgers'

const config = {
    id: 'user',
    initial: 'starting',
    context: {
        user: null
    },
    states: {
        starting: {
            on: {
                CHANGED: [
                    {
                        target: 'no_user',
                        cond: (_, event) => !event.user
                    },
                    {
                        target: 'authenticated',
                        actions: [
                            assign({
                                user: (_, event) => event.user
                            }),
                            'userAuthenticated'
                        ]
                    }
                ]
            }
        },
        no_user: {
            on: {
                CHANGED: [
                    {
                        target: 'authenticated',
                        cond: (_, event) => event.user,
                        actions: [
                            assign({
                                user: (_, event) => event.user
                            }),
                            'userAuthenticated'
                        ]
                    }
                ]
            }
        },
        authenticated: {
            on: {
                CHANGED: [
                    {
                        target: 'no_user',
                        cond: (_, event) => !event.user,
                        actions: [
                            assign({
                                user: (_, event) => event.user
                            }),
                            'loggedOut'
                        ]
                    },
                    {
                        actions: [
                            assign({
                                user: (_, event) => event.user
                            }),
                            'userAuthenticated'
                        ]
                    }
                ],
                SIGNOUT: {
                    actions: () => {
                        auth.signOut()
                    }
                }
            }
        }
    }
}

let notificationTimeout

export const machine = createMachine(config, {
    actions: {
        userAuthenticated: (_, event) => {
            notificationTimeout = $snackBars.show('sign-in', {
                message: `👋 Hello ${event.user.displayName}`,
                close: true
            }, 4)
            $feed.start(event.user.uid)
            const userRef = db.collection('users').doc(auth.currentUser.uid)
            
            userRef.set({
                displayName: event.user.displayName,
                email: event.user.email
            }, { merge: true })
            
            userRef.get()
                .then(doc => {
                    const { patches = [] } = doc.data()
                    const migrations = [
                        migration_20210712
                    ]
                    
                    migrations.filter(({ name }) => !patches.includes(name))
                        .shift()?.patch.call({ userRef })
                })
        },
        loggedOut: () => {
            $feed.stop()
            clearTimeout(notificationTimeout)
            $snackBars.show('user state', {
                message: 'Logged out!'
            }, 4)
        }
    },
    services: {},
    guards: {}
})
