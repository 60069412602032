import { reactive } from 'vue'
import { db } from '@/firebase'
import { $alert } from '@/services/alert'

const unsubscribe = {
    userledgers: null,
    ledgers: []
}

function unsubLedgers () {
    if (!unsubscribe.ledgers.length) return
    unsubscribe.ledgers.forEach(unsub => unsub())
    unsubscribe.ledgers = []
    $feed.data.ledgers = {}
}

function getLedger (ref) {
    unsubscribe.ledgers.push(ref
        .onSnapshot(
            doc => $feed.data.ledgers[ref.id] = doc,
            error => $alert.show(`Error reading jobs collection: ${error}`)
        )
    )
}


export const $feed = window.$feed = {
    data: reactive({
        ledgers: {},
        ready: false
    }),
    start (uid) {
        unsubscribe.userledgers?.()
        unsubscribe.userledgers = db.collection('users')
            .doc(uid)
            .onSnapshot(
                doc => {
                    unsubLedgers()
                    if (doc.exists) doc.data().ledgers?.forEach(getLedger)
                    this.data.ready = true
                },
                error => $alert.show(`Error reading jobs collection: ${error}`)
            )
    },
    stop () {
        this.data.ready = false
        unsubLedgers()
        unsubscribe.userledgers?.()
    }
}

export default {
    install: app => {
        app.config.globalProperties.$feed = $feed
    }
}
