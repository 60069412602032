<template>
    <header class="navbar">
        <div class="navbar__left">
            <router-link class="logo" to="/">
                <img class="spenda-img" src="@/assets/logo.svg" alt="Spenda">
                <span>Spenda</span>
            </router-link>
        </div>
        <div class="navbar__center">
        </div>
        <div class="navbar__right">
            <w3pp-account-button v-if="w3ppAccountButton" />
        </div>
    </header>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
    name: 'w3ppNavbar',
    components: {
        w3ppAccountButton: defineAsyncComponent(() => import('@/components/w3ppAccountButton'))
    },
    props: {
        w3ppAccountButton: Boolean
    }
}
</script>
<style lang="scss" scoped>
.navbar {
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: white;
    padding: 0 16px;
    box-shadow: 0 4px 2px 0 rgba(100, 121, 143, .12);
    .logo {
        color: inherit;
        font-size: 22px;
        text-decoration: none;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        .spenda-img {
            width: 36px;
            margin-right: 12px;
        }
    }
    & > div {
        display: flex;
        align-items: center;
    }
}
.navbar__right {
    & > :deep(.button + .button) {
        margin-left: 6px;
    }
}
</style>
