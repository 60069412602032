import router from '@/router'

export const $signin = {
    $onSignin: {
        redirect: '/'
    },
    get onSignin () {
        return this.$onSignin
    },
    set onSignin (value) {
        this.$onSignin = value
    },
    go (onSignin) {
        this.onSignin = onSignin
        router.push('/signin')
    }
}
