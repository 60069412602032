import { ledgers } from './ledgers.js'
import { $snackBars } from '@/services/snackBars'
import firebase, { db } from '@/firebase'

export default {
    name: '2021-07-12-create-default-ledgers',
    patch () {
        const userRef = this.userRef
        const addCustomLedger = function (config) {
            return db.collection('ledgers').add({
                ...config,
                members: firebase.firestore.FieldValue.arrayUnion(userRef)
            }).then(doc => {
                return userRef.set({
                    ledgers: firebase.firestore.FieldValue.arrayUnion(doc)
                }, { merge: true })
            })
        }
        
        Promise.allSettled(ledgers.map(addCustomLedger))
        .then(() => {
            userRef.set({
                patches: firebase.firestore.FieldValue.arrayUnion('2021-07-12-create-default-ledgers')
            }, { merge: true })
            .then(() => $snackBars.show('2021-07-12', {
                message: 'You have unlocked 6 new ledgers 🎉'
            }))
        })
        .catch(message => $snackBars.show('error', { message }))
    }
}