import { reactive } from 'vue'

/**
 *
 *
    $snackBars.set('unique id', {
        message: 'An update is available',
        action: {
            label: 'Update',
            fn: () => { $snackBars.delete('this') }
        }
    })

    $snackBars.delete('unique id')

    // auto delete
    $snackBars.show('unique id', {
        message: 'An update is available',
        action: {
            label: 'Update',
            fn: () => { $snackBars.delete('this') }
        }
    })
 */

export const $snackBars = reactive(new (class extends Map {
    // returns timeout object incase you want to clearTimeout else where
    show (key, value, ttl = 7) {
        this.set(key, value)
        const timeout = setTimeout(() => {
            clearTimeout(timeout)
            this.delete(key, value)
        }, 1000 * ttl)
        return timeout
    }
})())

export default {
    install: app => {
        app.config.globalProperties.$snackBars = $snackBars
    }
}
