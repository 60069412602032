export const ledgers = [
    {
        title: 'Food & Wellness 🍔',
        tags: [
            'Groceries',
            'Eating out & treats',
            'Order in',
            'Mr D Food',
            'Uber Eats',
            'Medicine',
            'Gym',
            'Medical Aid',
            'Gym snacks',
            'Toiletries'
        ],
        description: 'e.g Groceries, Uber Eats, Meds'
    },
    {
        title: 'Education & Tech 🎮',
        tags: [
            'Youtube',
            'Netflix',
            'Showmax',
            'Spotify',
            'Tinder',
            'Gaming',
            'Phone contract & prepaid',
            'Data',
            'Books',
            'Stationery',
            'Camera',
            'Scanning'
        ],
        description: 'e.g Data, Apps, Books'
    },
    {
        title: 'Commute & Travel 🚕',
        tags: [
            'Uber',
            'Transport',
            'Car use & service',
            'Car repayment',
            'Fuel',
            'Insurance',
            'Flight',
            'Kulula',
            'MyCiti'
        ],
        description: 'e.g Uber, Petrol, Flights'
    },
    {
        title: 'Beauty & Fashion 🛍',
        tags: [
            'Make Up',
            'Clothing',
            'Gift Card',
            'Sale',
            'Second-hand',
            'Wax',
            'Pedi',
            'Facial',
            'Perfume'
        ],
        description: 'e.g Wax, Cotton On, Thrift'
    },
    {
        title: 'Entertainment & Social 🍻',
        tags: [
            'Night Out',
            'Date Night',
            'Pre-drinks',
            'Wine tasting',
            'Road trip',
            'Coffee',
            'Festival',
            'Cocktails',
            'Drinks',
            'Sports Event',
            'Beach',
            'The Streets',
            'Club'
        ],
        description: 'e.g Drinks, Date Night, The Streets'
    },
    {
        title: 'Home & Family 🏡',
        tags: [
            'Rent',
            'Family Tax',
            'Nanny',
            'Child care',
            'Housekeeping',
            'SweepSouth',
            'Helper',
            'Cleaning Supplies',
            'New appliance',
            'New furniture',
        ],
        description: 'e.g Rent, Black Tax, Cleaning'
    },
]