<template>
  <div class="home">
    <h3 class="heading">
      Ledgers
      <create-ledger/>
    </h3>
    <div class="cards">
      <router-link
        class="card"
        :to="`/ledger/${key}`"
        :key="key"
        v-for="(ledger, key) of ledgers"
      >
        <div class="title">{{ ledger.title }}</div>
        <div v-if="ledger.description" class="helper">{{ledger.description}}</div>
      </router-link>
    </div>
    <div class="heading" v-if="invites.length">Pending invites</div>
    <div class="list">
      <div
        class="item"
        v-for="doc in invites"
        :key="doc.id"
      >
        <div class="title">{{ doc.data().title }}</div>
        <div class="actions">
          <w3pp-button
            background="#009688"
            class="icon"
            @click="accept(doc)"
          >
              <icon icon="checkIcon" height="21"/>
          </w3pp-button>
          <w3pp-button
            background="#f44336"
            class="icon reject"
            @click="removeInvite(doc)"
          >
              <icon icon="closeIcon" height="21"/>
          </w3pp-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import w3ppButton from '@/components/w3ppButton'
import firebase, { auth, db } from '@/firebase'
import closeIcon from '@iconify-icons/mdi/close'
import checkIcon from '@iconify-icons/mdi/check'
import { Icon, addIcon } from '@iconify/vue'
import CreateLedger from '@/components/createLedger.vue'
addIcon('closeIcon', closeIcon)
addIcon('checkIcon', checkIcon)

export default {
  name: "Home",
  components: {
    w3ppButton,
    Icon,
    CreateLedger
  },
  data () {
    return {
      invites: []
    }
  },
  computed: {
    ledgers () {
      return Object.values(this.$feed.data.ledgers).reduce((ledgers, doc) => ({
          ...ledgers,
          [doc.id]: doc.data()
        }), {})
    }
  },
  mounted () {
    let inviteListerners = []
    db.collection('invites').doc(auth.currentUser.email).onSnapshot(invite => {
      
      inviteListerners.forEach(fn => fn())
      this.invites = []
      
      invite.data()?.invites.map((ledger, i) => {
        inviteListerners[i] = ledger.onSnapshot(
          doc => this.invites[i] = doc,
          error => console.log('user ref error', error)
        )
      })
    })
  },
  methods: {
    removeInvite (doc) {
      db.collection('invites').doc(auth.currentUser.email).update({
        invites: firebase.firestore.FieldValue.arrayRemove(doc.ref)
      })

      doc.ref.update({
        invites: firebase.firestore.FieldValue.arrayRemove(auth.currentUser.email)
      })
    },
    accept (doc) {
      this.removeInvite(doc)
      const userRef = db.collection('users').doc(auth.currentUser.uid)
      doc.ref.update({
        members: firebase.firestore.FieldValue.arrayUnion(userRef)
      })
      userRef.update({
        ledgers: firebase.firestore.FieldValue.arrayUnion(doc.ref)
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.home {
  padding: 36px;

  @media (min-width: 720px) {
    padding: 72px;
  }
}

.cards {
    margin: auto;
    columns: 250px 5;
    column-gap: 16px;
    padding-bottom: 36px;

    .card {
        display: flex;
        flex-direction: column;
        background: white;
        padding: 16px;
        min-height: 60px;
        position: relative;
        break-inside: avoid;
        margin-bottom: 16px;
        border-radius: 8px;
        transition: background-color .1s cubic-bezier(0.4, 0, 0.2, 1);
        box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
        text-decoration: none;
        color: inherit;
        &:after {
          content: '';
          position: absolute;
          height: 1px;
          width: 1px;
          bottom: -3px;
          visibility: hidden;
        }

        @media (min-width: 720px) {
          min-height: 144px;
          align-items: center;
          justify-content: center;
        }
        .title {
          font-size: 16px;
          font-weight: 500;
          text-align: center;
        }

        &:hover {
          background-color: #f6f7f9;
          box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
          text-decoration: underline;
          cursor: pointer;
        }
    }
}

h3 {
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 8px;
  border-bottom: 1px solid #d4d8dc;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 8px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, .24);
  padding: 0 4px;
  &:hover {
    background: white;
  }
  .actions {
    display: flex;
    .reject {
      margin-left: 8px;
    }
  }
}

.helper {
  font-size: 12px;
  color: #aaa;
  text-align: center;
}
</style>